<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <delete-issue
      v-if="showDelete"
      :title="'Delete this ticket ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-face-agent
      </v-icon> Support Tickets
    </h2>
    <br>
    <v-alert
      v-if="!permissionCheck('read-support-ticket-list')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          v-if="permissionCheck('create-support-ticket')"
          color="#FF3700"
          @click="showForm = true"
        >
          Report Issue
        </v-btn>
      </v-col>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="finalIssueList"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>
              <font v-if="item.client_reference !== null">
                {{ item.client_reference }}
              </font>
              <font v-else>
                NA
              </font>
            </td>
            <td>
              <font v-if="item.digi_board_id !== null">
                {{ item.digi_board_id }}
              </font>
              <font v-else>
                NA
              </font>
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font
                :color="getColor(item.status)"
                class="font-my-style"
              >
                {{ item.status | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="deleteIssue(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <div>
        <support-form
          v-model="showForm"
          @get-latest-tickets="showTickets"
        />
      </div>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import SupportForm from './supportForm.vue';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';

  export default {
    name: 'Support',
    components: {
      SupportForm,
      'delete-issue': DeleteDialog,
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (status) {
        if (status === 'open') {
          return 'Open';
        } else if (status === 'inProgress') {
          return 'Working';
        } else if (status === 'done') {
          return 'Done*';
        } else {
          return 'Parked';
        }
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        showForm: false,
        showDelete: false,
        deleteId: '',
        headers: [
          { text: 'Ticket Name', align: 'start', value: 'name' },
          { text: 'Type', value: 'type' },
          { text: 'Reference No.', value: 'client_reference' },
          { text: 'Digi-Board Id', value: 'digi_board_id' },
          { text: 'Description', value: 'description' },
          { text: 'Reported At', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: 'Delete', value: 'delete' },
        ],
        loading: false,
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      finalIssueList () {
        return this.$store.getters['support/getFinalIssueList'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
     await this.showTickets();
    },
    methods: {
      async showTickets () {
        if (this.permissionCheck('read-support-ticket-list') === false) {
          return;
        }
        this.loading = true;
        await this.$store.dispatch('support/fetchSupportTickets', {
          params: {
            orderBy: 'created_at',
          },
        });
        this.loading = false;
      },
      deleteIssue (ticketId) {
        if (this.permissionCheck('delete-support-ticket') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDelete = true;
        this.deleteId = ticketId;
      },
      deleteConfirmed () {
        this.$store.dispatch('support/deleteIssueTicket', this.deleteId).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Ticket deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('support/clearIssueList');
          this.showTickets();
        }).finally(
          this.showDelete = false,
        );
      },
      getColor (status) {
        if (status === 'open') {
          return '#2E7D32';
        } else if (status === 'inProgress') {
          return '#EF6C00';
        } else if (status === 'done') {
          return '#37474F';
        } else {
          return '#C62828';
        }
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
